import { BrowserRouter , Routes , Route } from 'react-router-dom';
import './App.css';
// import Leitor from '../src/Pages/Leitor/index';
import Leitor2 from '../src/Pages/LeitorSemCortina/index.jsx';
import Seletor from '../src/Pages/Seletor/index';
import Home from '../src/Pages/Home/index';
import PageNotFound from '../src/Pages/PageNotFound/index';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/Leitor" element={<Leitor/>}/> */}
        <Route path="/Leitor/:arco/:capitulo/:pagina"  element={<Leitor2/>} />
        <Route path="/Seletor/:arco" exact element={<Seletor/>}/>
        <Route path="/" exact element={<Home/>}/>
        <Route path="*" element={<PageNotFound/>}/>
       </Routes>
    </BrowserRouter>

  )
}

export default App;
