import React from 'react'
import { useParams } from 'react-router-dom'
import Arcos from '../Interfaces/IArcos'

export default function TituloArco() {
    const { arco } = useParams() ;
    const arc = parseInt(arco , 10);
  return (
    <div className='conteudo-capitulos-titulo' background-color="#000000">
          {Arcos["arcos"][arc]["nome"]}
    </div>
  )
}
