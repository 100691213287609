import React from 'react'
import { useParams } from 'react-router-dom'


export default function ImagemArcos() {
    const {arco} = useParams();
      const arc = parseInt(arco , 10);

    const url = `https://s3.sa-east-1.amazonaws.com/murago.drive/arco${arc}/Faceset+${arc}`;



  return (
    <div><img className="conteudo-animacao-imagem" alt="magem do arco" src={url}/></div>
  )
}
