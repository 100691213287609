import React from 'react'
import { useParams } from 'react-router-dom';
import Arcos from '../Interfaces/IArcos'

import setaEsquerda from '../../Images/Seletor/seta_arco_esquerda.png'
import setaDireita from '../../Images/Seletor/seta_arco_direita.png'
import '../../Pages/Seletor/index.css'


export default function ArrowComp({direction, arcFunc}) {
  const {arco} = useParams();
  const arc = parseInt(arco , 10);
  var visibilityState = "visible"
  var arrowMap = {
    right: setaDireita,
    left: setaEsquerda
  }


  if (arc === Arcos["numeroArcos"]  && direction==="right"){ //Max number of Arcs
    var visibilityState = "hidden"
  }
  else if(arc === 1 && direction==="left"){ //Min number of Arcs
    var visibilityState = "hidden"
  }

  return (
    <img className='conteudo-animacao-seta-direita'
      alt="imagem seta"
      src={arrowMap[direction]}
      onClick={arcFunc}
      style={{visibility: visibilityState}}/>
  )
}
