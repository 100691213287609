import React from 'react';
import { useNavigate } from 'react-router-dom'
import Cortina from "../../Images/Cortinas/cortinas_vermelha.png";
import ImagemErro from "../../Images/PageNotFound/Error_404.png";
import BotaoInicio from '../../Images/leitor/btn_home.png';
import './index.css'

 export default function PageNotFound () {
    const navigate = useNavigate()
    const ToStart = () => {
        navigate("/")
      }

    return(
            <div className="conteudo2">
                <div className="cortina2">        
                    <div className='conteudo-botoes-navigate'>
                        <div className="conteudo-botao">
                            <img className="conteudo-botao-imagem" alt="botão de início" src={BotaoInicio} onClick={ToStart}/>
                        </div>
                    </div>
                        
                    <img className="cortina-imagem2" src={Cortina} alt="cortinas"></img>
                    <div className='conteudo-pagina'>
                        <img className='imagem-error404' src={ImagemErro} alt='imagem de erro'></img>
                        <div className='texto1'>Erro 404</div>
                        <div className='texto2'>Página não encontrada.</div>
                    </div>
                </div>
            </div>
    )
    }