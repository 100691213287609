import {React , useState} from 'react'
import {
  useNavigate,
 } from 'react-router-dom'
import "./index.css"
import CortinasSelecao from "../../Components/CortinasSeleção/index"
import BotaoInicio from '../../Images/leitor/btn_home.png';

import Moldura from "../../Images/Seletor/menu_capitulos_borda_compress.png"
import MenuSeletor from '../../Components/MenuSeletor'
import { useParams } from 'react-router-dom'
import Arcos from '../../Components/Interfaces/IArcos'
import ImagemArcos from '../../Components/ImagemArcos'
import TituloArco from '../../Components/TituloArco/index'
import ArrowComp from '../../Components/ArrowSeletor/index'


export default function Seletor() {
const {arco} = useParams()
const [arc, setArc] = useState(parseInt(arco, 10))
const navigate = useNavigate();

// const url = `https://examesmedicos.s3.sa-east-1.amazonaws.com/img_Arco_${arc}.png`;

const PreviousArc = () => {
  if (arc === 1) {
    setArc(1);
    return
  }
  const prevArc= arc - 1;
        setArc(prevArc);
    navigate(`/Seletor/${prevArc}`);
 }

 const NextArc = () => {
  const ultimoArco = Arcos["numeroArcos"];

  if(arc === ultimoArco) {
    setArc(ultimoArco);
    return
  }
  const nextArc= arc + 1;
        setArc(nextArc);
        navigate(`/Seletor/${nextArc}`);

 }

 const ToStart = () => {
  navigate("/")
}
 

  return (
    <div className="conteudo">
      <CortinasSelecao/>
      <div className='botao-inicio'>
        <img className="botao-inicio-img" alt="botão de início" src={BotaoInicio} onClick={ToStart}/>
      </div>
      <div className="conteudo-animacao">
        <ArrowComp direction={'left'} arcFunc={PreviousArc}/>
        <ImagemArcos/>
        <ArrowComp direction={'right'} arcFunc={NextArc}/>
      </div>
      <div className="conteudo-capitulos">
        {/* <div className='conteudo-capitulos-titulo'>
          {Arcos["arcos"][arc]["nome"]}
        </div> */}
        <TituloArco/>
        <img className="conteudo-capitulos-moldura" src={Moldura} alt="moldura"/>
          <div className='conteudo-capitulos-texto'>
            <div>Lista de capítulos</div>

            <MenuSeletor/>
          </div>
      </div>
    </div>
  )
}
