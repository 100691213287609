import {React ,} from 'react'
import { useParams } from 'react-router-dom';
import Arcos from '../Interfaces/IArcos'
import '../../Pages/Seletor/index.css'

export default function MenuSeletor() {
  const {arco} = useParams();
  const arc = parseInt(arco , 10);
  const list = Object.keys(Arcos["arcos"][arc]["capitulo"]);
  const firstChapter = Arcos["arcos"][arc]["primeiroCap"].toString()

  // return( <ol className='conteudo-capitulos-texto'>{list.map((item, id) => (
  return( <ol start={firstChapter} >{list.map((item, id) => (

      <li className="li" key={item}><a  className='lista'  href={`/Leitor/${arc}/${item}/1`}>{Arcos["arcos"][arc]["capitulo"][item]["nome"]}</a></li>
    ))}</ol>
  )
  }
