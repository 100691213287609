import  { React } from 'react';
import { useParams } from 'react-router-dom'

export default function ImagemPagina() {
    const { arco , capitulo , pagina} = useParams()

    const pag = parseInt(pagina , 10);
    const cap = parseInt(capitulo , 10);
    const arc = parseInt(arco ,10);


    const url = `https://s3.sa-east-1.amazonaws.com/murago.drive/arco${arc}/capitulo${cap}/Cap+${cap}+-+Pg+${pag}.jpg`

    

  return (
    <div>
        <img  className='conteudo-pagina-imagem2' alt="página" src={url} />
    </div>
  )
}
