import React from 'react'
import './index.css'
import Cortina from "../../Images/Cortinas/cortinas_vermelha_compress.png"

export default function CortinasSelecao() {
  return (
    <div className="conteudo1">
        <img className="conteudo-cortinas" src={Cortina} alt="cortinas"></img>
    </div>
  )
}