import {React , useRef, useState, useEffect} from 'react';
import { useNavigate , useParams }from 'react-router-dom';
import ProxPag from '../../Images/Botões_Muragô_SETA-DIREITA_-Páginas.png';
import AntPag from '../../Images/Botões_Muragô_SETA-ESQUERDA_-Páginas.png';
import Cortina from "../../Images/Cortinas/cortinas_vermelha.png";
import Mostrador from "../../Images/Botoes_Murago_CENTRAL-menor-PG-LEITURA.png";
// import BotaoAmareloPag from '../../Images/MainPage/Botões_Muragô_CENTRAL-Menor-FRONT-PAGE.png'


import './index2.css';
// import './media_query.css';



import Arcos from '../../Components/Interfaces/IArcos';
import BotaoInicio from '../../Images/leitor/btn_home.png';
import BotaoSeletor from '../../Images/leitor/btn_selector.png';
import ImagemPagina from '../../Components/ImagemPagina';
import TextoContador from '../../Components/TextoContador';
import ImagemTitulo from  '../../Images/Botoes_Murago_CENTRAL-PG-LEITURA.png';
import TextoTitulo from '../../Components/TextoTitulo/index.jsx';
import DropDown2 from '../../Components/Dropdown';

export default function Leitor2() {
    const {arco , capitulo , pagina} = useParams()

    const navigate = useNavigate()
    const [contentHover, setContentHover] = useState(false);
    const [pag, setPag] = useState(parseInt(pagina , 10));
    const [cap, setCap] = useState(parseInt(capitulo , 10));
    const [arc, setArc] = useState(parseInt(arco , 10));
    const myRef = useRef(true);
    const contentRef = useRef(null);
    const selectRef = useRef(null);
    const numPag = Arcos["arcos"][arc]["capitulo"][cap]["numeroPaginas"];
    var _ = require('lodash');
    const arrayPag = _.range(1,numPag+1,1);

    // const tamanhoCapitulo = [0 , 10 , 2];
    // const url = `https://examesmedicos.s3.sa-east-1.amazonaws.com/Cap+${cap}+-+Pg+${pag}.jpg`;


    // Botão de ir para a Main Page no topo superior da 
    const ToStart = () => {
        navigate("/")
    }

    const ToSelector = () => {
        navigate("/Seletor/"+arc)
    }

    const Previous = () => {
        // const atualLastCap = Arcos["arcos"][arc]["ultimoCap"];
        // 1ª pagina do 1º capítulo do 1º arco
        if (arc ===1 & cap === 0 & pag === 1) {
            setCap(1);
            setPag(1);
            navigate(`/Leitor/1/0/1`);
            return
        }
        // 1º página do 1º capítulo de arcos>1
        if(cap === Arcos["arcos"][arc]["primeiroCap"] & pag === 1) {
            const prevArc = arc - 1;
            setArc(prevArc);
            const prevLastCap = Arcos["arcos"][prevArc]["ultimoCap"];
            setCap(prevLastCap);
            navigate(`/Leitor/${prevArc}/${prevLastCap}/1`);
            ToTop();
            return
        }
        // 1ª página de qualquer capitulo>1
        if(pag === 1){
            const prevCap = cap - 1;
            setCap(prevCap);
            setPag(1);
            navigate(`/Leitor/${arc}/${prevCap}/1`);
            ToTop();
            return
        }
        // qualquer página
        const prevPage = pag - 1;
        setPag(prevPage);
        navigate(`/Leitor/${arc}/${cap}/${prevPage}`);
        ToTop();
    }
   

    
    const ToTop = () => {
         myRef.current.scrollIntoView(true)
    }

    const Next = () => {
        const lastPagCap = Arcos["arcos"][arc]["capitulo"][cap]["numeroPaginas"];
        let cap_aux = cap;
            // trocando capitulos
        if(pag === lastPagCap) {
            cap_aux += 1;
                // trocando de arcos
            if(cap_aux > Arcos["arcos"][arc]["ultimoCap"]) {
                const nextArc = arc + 1;
                const lastArc = Arcos["ultimoArcosLegivel"]; //depois de cada arco ir para seleção
                // const lastArc = Arcos["numeroArcos"];     //Depois de cada arco ir para proxima pagina do proximo arc
                const lastCap = Arcos["arcos"][lastArc]["ultimoCap"];
                const lastPag = Arcos["arcos"][lastArc]["capitulo"][lastCap]["numeroPaginas"];

                if(nextArc >= lastArc & cap === lastCap & pag ===lastPag) {
                    // ultimo caso de todos
                    if(nextArc >= lastArc & cap === lastCap & pag === lastPag) {
                        setArc(lastArc);
                        setCap(lastCap);
                        setPag(lastPag);
                        // navigate(`/Leitor/${lastArc}/${lastCap}/${lastPag}`);//Depois de cada arco ir para proxima pagina do proximo arc
                        navigate(`/Seletor/${arc}`); //depois de cada arco ir para seleção do arc que acabou
                        ToTop();
                        return
                    }
                    //troca de capitulo no ultimo arco
                    setArc(lastArc);
                    setCap(cap_aux);
                    setPag(1);
                    navigate(`/Leitor/${arc}/${cap_aux}/1`);
                    ToTop();
                    return
                }
                //troca de arco
                const firstCap = Arcos["arcos"][nextArc]["primeiroCap"];
                setPag(1);
                setCap(firstCap);
                setArc(nextArc);
                navigate(`/Leitor/${nextArc}/${firstCap}/1`);
                ToTop();
                return
                }
                //troca de capitulo
            setCap(cap_aux);
            setPag(1);
            navigate(`/Leitor/${arc}/${cap_aux}/1`);
            ToTop();
            return
            }
        // trocando páginas
        const nextPag= pag + 1;
        setPag(nextPag);
        navigate(`/Leitor/${arc}/${cap}/${nextPag}`)
        ToTop();
        }

    const pressKey = (e) => {
        if(e.key === "ArrowRight"){
            Next()
        }
        else if (e.key === "ArrowLeft"){
            Previous()
        } 
        else if(e.key === "ArrowUp"){
            contentRef.current.scrollTo({
                top: contentRef.current.scrollTop - 150,
                behavior: "auto",
            });
        }
        else if(e.key === "ArrowDown"){
            contentRef.current.scrollTo({
                top: contentRef.current.scrollTop + 150,
                behavior: "auto",
            });
        }
    }

    const disableKeyInSelect = (e) =>{
        if(e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "ArrowRight" || e.key === "ArrowLeft"){
            selectRef.current.focus();
            e.preventDefault();
        }
    }

    useEffect(()=>{
        document.addEventListener('keyup',pressKey)
        return () => document.removeEventListener("keyup", pressKey); // Needs this line for not adding multiple event listeners and multiplying inputs
    })

    const Teste = (e) => {

        setPag(parseInt(e.target.value , 10))
        navigate(`/Leitor/${arc}/${cap}/${e.target.value}`)
    }

   // Scroll anywhere!
    const handleScrolling = (event) => {
        if(contentRef !== null) {
            if(contentHover === false){
                contentRef.current.scrollTo({
                top: contentRef.current.scrollTop + event.deltaY,
                behavior: "instant",
                });
            }
        }
    }
    useEffect(()=>{
        window.addEventListener('wheel',handleScrolling)
        return () => window.removeEventListener("wheel", handleScrolling); // Needs this line for not adding multiple event listeners and multiplying inputs
    })


       return (

        <div className="conteudo2">
            <div className="cortina2">
                
                <img className="cortina-imagem2" src={Cortina} alt="cortinas"></img>
                <div className='conteudo-botoes-navigate'>

                    <div className="conteudo-botao">
                        <img className="conteudo-botao-imagem" alt="botão de início" src={BotaoInicio} onClick={ToStart}/>

                    </div>
                    <div className="conteudo-botao-2">
                        <img className="conteudo-botao-imagem-selector" alt="botão de início" src={BotaoSeletor} onClick={ToSelector}/>
                    </div>
                </div>

                <div  
                    className="conteudo-pagina2"
                    ref={contentRef}
                    onMouseEnter={ () => { setContentHover(true) }}
                    onMouseLeave={ () => { setContentHover(false) }} 
                >


                    <div ref={myRef} className="change-page-column"  id='upper'>
                        <img className="seta-esquerda" src={AntPag} alt='seta para mudar página' onClick={Previous}/>
                        
                        <div  className='botao-dropdown'>
                            <select 
                                ref={selectRef}
                                className='botao-dropdown-select'
                                value={pag}
                                onChange={(e) => {Teste(e)}}
                                onKeyDown={(e)=> {disableKeyInSelect(e)}}
                            >
                                {arrayPag.map( item => (<option  key={item} value={item}> Página {item} </option>) )}
                            </select>
                            <img className='botao-dropdown-imagem' src={Mostrador} alt="Número da página"/>
                        </div>

                        <img className="seta-direita"src={ProxPag} alt='seta para mudar página' onClick={Next}/>
                    </div>
                    <div className="conteudo-controle-titulo">
                        <img className="conteudo-controle-nome-imagem" alt="imagem fundo do titulo capítulo" src={ImagemTitulo} />
                        {/* <div className="conteudo-controle-nome-texto"> */}
                        <TextoTitulo/>
                        {/* </div> */}
                    </div>
                    <picture onClick={Next}>
                        {/* <img className='conteudo-pagina-imagem2' alt="página" src={url} /> */}
                        <ImagemPagina/>
                    </picture>
                    
                    <div className="change-page-column" id='bottom' >
                        <img className="seta-esquerda" src={AntPag} alt='seta para mudar página' onClick={Previous}/>
                        
                        <div className='botao-dropdown'>
                            <select  className='botao-dropdown-select' value={pag} onChange={(e) => {Teste(e)}}>
                                {arrayPag.map( item => (<option key={item} value={item}> Página {item} </option>) )}
                            </select>
                            <img className='botao-dropdown-imagem' src={Mostrador} alt="Número da página"/>
                        </div>

                        <img className="seta-direita"src={ProxPag} alt='seta para mudar página' onClick={Next}/>
                    </div>

                </div>
            </div>
        </div>
    )
}
