import {React,useState} from 'react';
import Arcos from '../../Components/Interfaces/IArcos'
import Logo from '../../Images/MainPage/logo_murago.png'
import faceArc1 from '../../Images/MainPage/face_arco1_compress.png'
import BotaoMaior from '../../Images/MainPage/botao_vermelho.png'
import Botao from '../../Images/MainPage/botao_amarelo_pequeno.png'
import BotaoDoacao from '../../Images/MainPage/botao_amarelo_contribua_compress.png'
import SetaExtra from '../../Images/MainPage/seta_baixo_extras_compress.png'

import './index.css'
import { useNavigate } from 'react-router-dom';

export default function Home() {
    const navigate = useNavigate()
    const ultimoArco = Arcos["ultimoArcosLegivel"];
    const ultimoCap = Arcos["arcos"][ultimoArco]["ultimoCap"];
    
    const GenerateUrl = `https://s3.sa-east-1.amazonaws.com/murago.drive/arco${ultimoArco}/Faceset+${ultimoArco}`;


    const Seletor = () => {
        navigate('/Seletor/1')
    }

    const UltimoCap = () => {
        //linha comentada para que no futuro seja possivel ler o ultimo capítulo lançado
        // navigate(`/Leitor2/${ultimoArco}/${ultimoCap}/1`)
        
        navigate(`/Leitor/${ultimoArco}/${0}/1`)
    }


    const faceArc = {
        1:faceArc1,
        2:GenerateUrl
    }
    return (

        <div className="conteudo">
            <div className="conteudo-superior">
                <div className="conteudo-superior-principal">
                    <div className="conteudo-superior-principal-logo">
                        {/* <MusicButton musicUrl={homeMusic}/> */}
                        <img alt="logo Murago" src={Logo}/>
                    </div>
                    <div className="conteudo-superior-principal-botoes">
                        <div className="botao-maior" onClick={UltimoCap}>
                            <img className="botao-maior-imagem" alt="botao seleção de capitulos" src={BotaoMaior}/>
                            <div className="botao-maior-texto">ÚLTIMO CAPÍTULO</div>
                        </div>
                        <div className="botao-amarelo" onClick={UltimoCap}>
                            <img className="botao-amarelo-imagem" alt="botao seleção de capitulos" src={Botao} />
                            <div className="botao-amarelo-conteudo">
                                <img className='botao-arco-imagem' src={faceArc[ultimoArco]} alt="Imagem arco"></img>
                                {/* <div className="botao-amarelo-conteudo-texto">{ultimoArco}:{ultimoCap}</div> */}
                                <div className="botao-amarelo-conteudo-texto">{ultimoArco}:{0}</div>
                            </div>
                        </div>
                        <div className="botao-menor" onClick={Seletor}>
                            <img className="botao-menor-imagem" alt="botao seleção de capitulos" src={BotaoMaior} />
                            <div className="botao-menor-texto">SELEÇÃO DE CAPÍTULOS</div>
                        </div>
                        <div className='botao-doacao' >
                            <img className="botao-doacao-imagem" alt="botao para doação" src={BotaoDoacao}/>
                            <a className="botao-doacao-texto" href="https://kachec-shop.lojaintegrada.com.br/livros-21938139" target="_blank">Compre aqui a versão física!</a>
                        </div>
                        <div className="seta-extras">
                            <img className="seta-extras-imagem" alt="seta indicando conteúdo abaixo" src={SetaExtra}></img>
                        </div>
                        <div className="seta-extras-texto">{"- EXTRAS -"}</div>
                    </div>
                </div>
            </div>
            <div className="conteudo-inferior">
                <div className='grid-container-rows'>

                    {/* <div className='empty-space-1' >aaaa</div> */}
                    <div className='follow-us-columns' >{/*Row1 */}
                    <div className='grid-item-2' ></div>
                        <div className='social-media-grid' >
                            <div className='tiktok-shell'>
                                    <a href="https://www.tiktok.com/@kachec3?_t=8pwM1Zkr6Jx&_r=1" target='_blank'>
                                        <div className='tiktok-link'>
                                        
                                        </div>
                                    </a>
                            </div>
                            <div className='facebook-shell'>
                                <a href="https://www.facebook.com/profile.php?id=61566553173618&mibextid=ZbWKwL" target='_blank'>
                                    <div className='facebook-link'>
                                    
                                    </div>
                                </a>
                            </div>
                            <div className='insta-shell'>
                                <a href="https://www.instagram.com/kachec.arte/" target='_blank' >
                                        <div className='insta-link'>
                                        
                                        </div>
                                </a>
                            </div>
                            <div className='twitter-shell'>
                                {/* <a href="https://twitter.com/kachec1" target='_blank' >
                                    <img className="twitter-image" alt="Twitter" src={TwitterImage}/>
                                </a> */}
                                <a href="https://bsky.app/profile/muragohq.bsky.social" target='_blank' >
                                    <div className='twitter-link'>
                                    
                                    </div>
                                </a>
                            </div>

                        </div>
                        <div className='grid-item-2' ></div>
                    </div> 


                    <div className='empty-space-1'>{/*Row2 */}</div>

                    <div className='contribute-columns'>{/*Row3 */}
                        <div className='grid-item-2' ></div>
                        <div className='contribute-shell' >
                            <a href="https://kachec-shop.lojaintegrada.com.br/livros-21938139" target='_blank'>
                                <div className='contribute-link'>
                                    
                                </div>
                            </a>
                        </div>
                        <div className='grid-item-2' ></div>


                    </div>

                    <div className='shopping-columns' > {/*Row4 */}
                        <div className='shopping-shell'>
                                    <a href="https://kachec-shop.lojaintegrada.com.br" target='_blank' >
                                        <div className='shopping-link'>
                                        
                                        </div>
                                    </a>
                            </div>
                    </div>


                    <div className='empty-space-1' ></div>{/*Row5 */}

                    <div className='music-columns' >{/*Row6 */}
                        <div className='music-shell'>
                                <a href="https://www.youtube.com/watch?v=q5AejQ7Kqyw&list=PLSCYAUG2aQwRhU3uD6Ebg3oNN1D6YhHqE" target='_blank' >
                                    <div className='music-link'>
                                    
                                    </div>
                                </a>
                        </div>
                    </div>
                    <div className='lol-columns' >{/*Row7 */}
                        <div className='lol-shell'>
                           
                                <a href="http://thelegendoflink.com.br/" target='_blank' >
                                    <div className='lol-link'>
                                    
                                    </div>
                                </a>
                        </div>
                    </div>
                   

                </div>


  
         
            </div>
        </div>
    )
}
